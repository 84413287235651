import React from "react";

export const Coctails = ({menu}) => {
    return (<>
        <div className="row row-title">
            <div className="col-6 title">Назва товару</div>
            <div className="col-2 exit">Вихід<br /><small>(мл.)</small></div>
            <div className="col-2 exit">Ціна</div>
        </div>
        {menu.map(el => (
                <div className="row" key={el.id}>
                    <div className="col-6 title">
                        {el.title}
                        {el.description.length === 0 ? null : (<><br />
                            <small>({el.description})</small></>)}

                    </div>
                    <div className="col-2 exit">{el.bottle}</div>
                    <div className="col-2 exit">{parseFloat(el.price) === 0 ? "-" : parseFloat(el.price)}</div>
                </div>
            ))}
    </>)
}