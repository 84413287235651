import React from "react";

export const MenuRow = ({item, rowClass = ''}) => <div className={"row " + rowClass}>
    <div className="col-6 title">
        {item.title}
        {item.description.length === 0 ? null : (<><br />
            <small>({item.description})</small></>)}

    </div>
    <div className="col-2 exit">{rowClass.length !==0 ? item.exit : item.exit.split('/').join(' / ')}</div>
    <div className="col-2 price">{rowClass.length !==0 ? item.price : parseFloat(item.price) === 0 ? "-" : parseFloat(item.price)}</div>
</div>