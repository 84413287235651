import React, {Component} from 'react';
import {Route, Switch, Redirect, BrowserRouter} from 'react-router-dom'
import './App.scss';
import {Main} from "./pages/Main/Main";
import {Titles} from "./pages/Titles/Titles";
import {Menu} from "./pages/Menu/Menu";
import {CategoryState} from "./context/category/CategoryState";

class App extends Component {

  render() {
    let routes = (
        <Switch>
            <Route path="/" exact component={Main} />
            <Route path="/titles/:id" component={Titles} />
            <Route path="/menu/:id" component={Menu} />
          <Redirect to="/" />
        </Switch>
    )

    return (
        <CategoryState>
            <BrowserRouter>
                <div className="App">
                    { routes }
                </div>
            </BrowserRouter>
        </CategoryState>
    );
  }
}

export default App;
