import React, {useContext, useEffect} from "react";
import {NavLink} from "react-router-dom"
import {CategoryContext} from "../../context/category/categoryContext";
import "./Titles.scss"
import {Logo} from "../../components/logo/Logo";

export const Titles = ({match}) => {
    const {getCategory, getTitles, titles} = useContext(CategoryContext)

    useEffect(() => {
        getCategory(match.params.id)
        getTitles(match.params.id)
        // eslint-disable-next-line
    }, [])

    return (
        <div className="titles">
            <div className="logo">
                <Logo />
            </div>
            <div className="titles-nav">
                {titles.map(el => {
                    if (el.parent_id === null)
                        return null

                    return <NavLink key={el.id} to={`/menu/${el.id}`}>
                        <span>{el.title}</span>
                    </NavLink>})}
            </div>
        </div>
    )
}