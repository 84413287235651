import React from "react";
import {NavLink} from "react-router-dom"
import './Main.scss';
import {Logo} from "../../components/logo/Logo";

export const Main = () => {

    const links = [
        {route: '/titles/1', exact: false, label: 'Основне меню'},
        {route: '/titles/2', exact: false, label: 'Напої'},
    ]

    return (<div className='main'>
        <div className="logo">
            <Logo />
        </div>
        <h2>Меню</h2>
        <div className="main-nav">
            {links.map((link, index) => (
                <NavLink key={index} to={link.route} exact={link.exact}>
                    <span>{link.label}</span>
                </NavLink>
            ))}
        </div>
    </div>)
}