import React, {useReducer} from "react";
import {CategoryContext} from "./categoryContext";
import {categoryReducer} from "./categoryReducer";
import {GET_CATEGORIES, GET_CATEGORY, GET_MENU, GET_SUB_CATEGORIES, GET_TITLES} from "../types";
import categorySource from "../../data/categories.json"
import subCategorySource from "../../data/sub_categories.json"
import menuSource from "../../data/items.json"


export const CategoryState = ({children}) => {
    const initialState = {
        categories: [],
        sub_categories: [],
        category: {},
        titles: [],
        menu: []
    }

    const [state, dispatch] = useReducer(categoryReducer, initialState)

    const getCategory = id => {
        getCategories()
        const category = categorySource.find(el => el.id === parseInt(id))

        dispatch({
            type: GET_CATEGORY,
            payload: category
        })
    }

    const getSubCategories = categoryId => {
        const categories = subCategorySource.filter(el => el.category_id === parseInt(categoryId))

        dispatch({
            type: GET_SUB_CATEGORIES,
            payload: categories
        })
    }

    const getTitles = categoryId => {
        const titles = categorySource.filter(el => el.parent_id === parseInt(categoryId))

        dispatch({
            type: GET_TITLES,
            payload: titles
        })
    }

    const getCategories = () => {
        const categories = categorySource;

        dispatch({
            type: GET_CATEGORIES,
            payload: categories
        })
    }

    const getMenu = categoryId => {
        const menu = menuSource.filter(el => el.category_id === parseInt(categoryId))

        dispatch({
            type: GET_MENU,
            payload: menu
        })
    }

    const {categories, category, titles, menu, sub_categories} = state

    return (
        <CategoryContext.Provider value={{
            getCategory, getTitles, getCategories, getMenu, getSubCategories,
            categories, category, titles, menu, sub_categories
        }}>
            {children}
        </CategoryContext.Provider>
    )
}