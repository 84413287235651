import React from "react";
import {MenuRow} from "../menu_row/MenuRow";

export const Alcohol = ({menu, sub_categories}) => {
    // eslint-disable-next-line array-callback-return
    const withoutCategories = menu.filter(el => {
        if (typeof el['sub_category_id'] === 'undefined' || el.sub_category_id !== '' || el.sub_category_id !== 0)
            return el
    })

    // eslint-disable-next-line array-callback-return
    const witCategories = menu.filter(el => {
        if (typeof el['sub_category_id'] !== 'undefined' && el.sub_category_id !== '' && el.sub_category_id !== 0)
            return el
    })

    const data = (<>
        <div className="row row-title">
            <div className="col-4 title">Назва товару</div>
            <div className="col-2 exit">Ємність<br /><small>(л.)</small></div>
            <div className="col-2 exit">Ціна<br /><small>(1 пл.)</small></div>
            <div className="col-2 exit">Ціна<br /><small>(100 гр.)</small></div>
            <div className="col-2 exit">Ціна<br /><small>(50 гр.)</small></div>
        </div>
        {withoutCategories.map(el => {
            if (typeof el['sub_category_id'] !== 'undefined' && (el.sub_category_id !== '' || el.sub_category_id !== 0))
                return null;

            return <div className="row" key={el.id}>
                <div className="col-4 title">
                    {el.title}
                    {el.description.length === 0 ? null : (<><br />
                        <small>({el.description})</small></>)}

                </div>
                <div className="col-2 exit">{el.bottle}</div>
                <div className="col-2 exit">{parseFloat(el.price_1pl) === 0 ? "-" : parseFloat(el.price_1pl)}</div>
                <div className="col-2 exit">{parseFloat(el.price_0_100) === 0 ? "-" : parseFloat(el.price_0_100)}</div>
                <div className="col-2 exit">{parseFloat(el.price_0_050) === 0 ? "-" : parseFloat(el.price_0_050)}</div>
            </div>
        })}
    </>)

    const dataCat = (<>
        {sub_categories.map(cat => {
            // eslint-disable-next-line array-callback-return
            const items = witCategories.filter(i => i.sub_category_id === cat.id)
            if (items.length === 0)
                return null

            return (<React.Fragment key={cat.id}>
                <MenuRow key={cat.id} rowClass={"sub_title"} item={{
                    title: cat.title,
                    description: '',
                    exit: '',
                    price: '',
                }} />
                {items.map(el => {
                    return <div className="row" key={el.id}>
                        <div className="col-4 title">
                            {el.title}
                            {el.description.length === 0 ? null : (<><br />
                                <small>({el.description})</small></>)}

                        </div>
                        <div className="col-2 exit">{el.bottle}</div>
                        <div className="col-2 exit">{parseFloat(el.price_1pl) === 0 ? "-" : parseFloat(el.price_1pl)}</div>
                        <div className="col-2 exit">{parseFloat(el.price_0_100) === 0 ? "-" : parseFloat(el.price_0_100)}</div>
                        <div className="col-2 exit">{parseFloat(el.price_0_050) === 0 ? "-" : parseFloat(el.price_0_050)}</div>
                    </div>
                })}
            </React.Fragment>)
        })}
    </>)

    return <>{data}{dataCat}</>
}