import React from "react";
import {MenuRow} from "../menu_row/MenuRow";

export const Eat = ({menu, sub_categories}) => {
    // eslint-disable-next-line array-callback-return
    const withoutCategories = menu.filter(el => {
        if (typeof el['sub_category_id'] === 'undefined' || el.sub_category_id !== '' || el.sub_category_id !== 0)
            return el
    })

    // eslint-disable-next-line array-callback-return
    const witCategories = menu.filter(el => {
        if (typeof el['sub_category_id'] !== 'undefined' && el.sub_category_id !== '' && el.sub_category_id !== 0)
            return el
    })

    const data = (<>
        <MenuRow rowClass={"row-title"} item={{
            title: 'Назва товару',
            description: '',
            exit: <span>Вихід<br /><small>(грам)</small></span>,
            price: <span>Ціна<br /><small>(грн.)</small></span>,
        }} />
        {withoutCategories.map(el => {
            if (typeof el['sub_category_id'] !== 'undefined' && (el.sub_category_id !== '' || el.sub_category_id !== 0))
                return null;

            return <MenuRow key={el.id} item={el} />
        })}
    </>)

    let dataCat = sub_categories.map(cat => {
        // eslint-disable-next-line array-callback-return
        const items = witCategories.filter(i => i.sub_category_id === cat.id)
        if (items.length === 0)
            return null

        return (<React.Fragment key={cat.id}>
            <MenuRow key={cat.id} rowClass={"sub_title"} item={{
                title: cat.title,
                description: '',
                exit: '',
                price: '',
            }} />
            {items.map(el => {
                return <MenuRow key={el.id} item={el} />
            })}
        </React.Fragment>)
    })

    return <>{data}{dataCat}</>
}