import {GET_CATEGORIES, GET_CATEGORY, GET_MENU, GET_SUB_CATEGORIES, GET_TITLES} from "../types";

const handlers = {
    [GET_CATEGORIES]: (state, action) => ({...state, categories: action.payload}),
    [GET_SUB_CATEGORIES]: (state, action) => ({...state, sub_categories: action.payload}),
    [GET_TITLES]: (state, action) => ({...state, titles: action.payload}),
    [GET_MENU]: (state, action) => ({...state, menu: action.payload}),
    [GET_CATEGORY]: (state, action) => ({...state, category: action.payload}),
    DEFAULT: state => state
}

export const categoryReducer = (state, action) => {
    const handler = handlers[action.type] || handlers.DEFAULT
    return handler(state, action)
}